/* You can add global styles to this file, and also import other style files */

.confirm {
    border: 2px solid #ff6b41;
    border-radius: 10px;
    padding: 10px;
    margin-top: 15px;
   // margin-bottom: auto;
}

.confirm-btn, .confirm-btn:hover {
    background: #ff6b41;
    color: white;
}